<template>

  <div class="justify-center text-center mt-10 text-white grid grid-cols-1 gap-10">

    <div id="hero" class="">
      <img src="/img/logo.png" class="w-40 m-auto"/>
      <div class="col-span-2 md:col-span-3 align-middle h-full">
        <h1 class="font-title text-9xl mb-5">Lunega</h1>
        <p class="font-title">Building tools to help you automate the parts of your creative process that <i class="bg-orchid-600">should</i> be automated. Not the fun parts.</p>
      </div>
    </div>

    <div id="socials">
      <a href="https://twitter.com/lunegadev">Twitter</a>
    </div>

<!--    <div id="products" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 text-left">-->

<!--      <div class="bg-white text-black rounded-md p-5">-->
<!--        <h2 class="text-4xl">Studio</h2>-->
<!--      </div>-->

<!--    </div>-->

  </div>



</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style lang="less">

@import "@/assets/less/fonts.less";

@tailwind base;
@tailwind components;
@tailwind utilities;

.title {
  font-family: 'BioRhyme', cursive;
}

</style>
